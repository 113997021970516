<template>
  <div>
    <div class="m_New_page" v-if="isMobile">
      <div class="banner_wrap">
        <p class="title ">新闻中心</p>
      </div>
      <div class="news_wrap">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="showMore(item.id)"
        >
        <img
              :src="'https://en-api-hk.unre.com/tempFiles/' + item.coverImage"
              alt=""
              srcset=""
            />
          <p class="title">{{ item.zhTitle }}</p>
          <p class="time">{{ dayjs(item.date).format("YYYY-MM-DD") }}</p>
        </div>
      </div>
    </div>
    <div class="New_page" v-else>
      <div class="banner_wrap">
        <p class="title fadeInLeft slow animated wow">新闻中心</p>
      </div>

 

      <div class="news_wrap">
        <div class="left">
          <div class="time" v-for="(item, index) in list" :key="item.coverImage">
            <span>{{ dayjs(item.date).format("YYYY-MM-DD") }}</span>
            <div class="time_c">
              <div class="a_line" v-show="index === 0"></div>
              <div class="dot"></div>
              <div class="h_line"></div>
            </div>
            <div class="line"></div>
          </div>
        </div>
        <div class="right">
          <div
            class="item wow fadeInUp animated"
            data-wow-offset="10"
            data-wow-delay=".2s"
            v-for="(item, index) in list"
            :key="index"
          >
            <img
              :src="'https://en-api-hk.unre.com/tempFiles/' + item.coverImage"
              alt=""
              srcset=""
            />
            <div class="new_des">
              <div class="title">{{ item.zhTitle }}</div>
              <p class="des">{{ item.zhIntro }}</p>
              <div class="btn" @click="showMore(item.id)">立即阅读</div>
            </div>
          </div>
        </div>
      </div>

      <div class="page_wrap">
        <img src="@/assets/left.png" alt="" srcset="" @click="conPage(-1)" />
        <div
          class="item"
          v-for="(item, index) in pageCount"
          :key="index"
          :class="{ active: page === index + 1 }"
          @click="openPage(index + 1)"
        >
          {{ index + 1 }}
        </div>
        <img src="@/assets/right.png" alt="" srcset="" @click="conPage(1)" />
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils";
import {WOW} from "wowjs"
import axios from "axios";
import dayjs from "dayjs";
export default {
  name: "New",

  data() {
    return {
      isMobile: false,
      list: [],
      page: 1,
      dayjs: dayjs,
      pageSize: 4,
      pageCount: 0,
    };
  },
  components: {},
  created() {
    this.isMobile = isMobile();
    this.getList(1);
  },
  mounted() {
    
    this.$nextTick(() => {
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });

  },
  methods: {
    getList(page) {
      axios
        .get("https://en-api-hk.unre.com/blogs/query", {
          params: {
            page: page,
            pageSize: this.isMobile ? 1000 : this.pageSize,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          this.list = response.data.data.rows;
          this.pageCount = Math.ceil(response.data.data.count / this.pageSize);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showMore(id) {
      this.$router.push({
        path: "/Info",
        query: { id: id },
      });
    },
    openPage(index) {
      this.getList(index);
      this.page = index;
      document.documentElement.scrollTop = 0;
    },
    conPage(mark) {
      if (this.page + mark < 0) {
        this.page = 0;
      } else if (this.page + mark > 2) {
        this.page = 2;
      } else {
        this.page = this.page + mark;
        document.documentElement.scrollTop = 0;
      }
    },
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
  },
};
</script>

<style lang="less">
.m_New_page {
  .banner_wrap {
    background-image: url(../assets/new_bg_m.png);
    background-size: cover;
    width: 10rem;
    height: 17.27rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      display: block;
      margin-bottom: 0.1rem;
      color: #fcfcfc;
      font-size: 0.64rem;
    }
  }
  .news_wrap {
    padding: 0.64rem 0.43rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      text-align: left;
    position: relative;
      background: #edf1f7;
      border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
      img {
        width: 100%;
        margin-bottom: 0.32rem;
      }
      .title {
        margin-bottom: 0.24rem;
        color: #4d4d4d;
        font-size: 0.29rem;
        padding: 0 0.24rem;
        overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
      }
      .time {
        padding: 0 0.24rem;
        font-size: 0.27rem;
        color: #808080;
   

      }
      margin-bottom: 0.48rem;
      width: 4.43rem;
      height: 5.2rem;
    }
  }
}
.New_page {
  .banner_wrap {
    background-image: url(../assets/new_bg.png);
    background-size: cover;
    width: 10rem;
    height: 2.88rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      display: block;
      margin-bottom: 0.1rem;
      color: #fcfcfc;
      font-size: 0.25rem;
    }
  }

  .news_wrap {
    display: flex;
    justify-content: center;
    background: #fcfcfc;
    padding: 0.32rem 0 0;
    .right {
      .item {
        width: 6.26rem;
        height: 2.35rem;
        background: #edf1f7;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.29rem;
        img {
          width: 2.21rem;
          margin-right: 0.2rem;
        }
      }
      .new_des {
        width: 3.27rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          margin-bottom: 0.1rem;

          font-size: 0.15rem;
          line-height: 0.17rem;
          color: #02aeec;
          font-weight: 600;
          color: #333333;
        }

        .des {
          margin-bottom: 0.12rem;

line-height: 0.16rem;
          color: #666666;
          font-weight: 500;

          font-size: .084rem;
        }
        .btn {
          width: 0.8rem;
          height: 0.26rem;
          background: #02aeec;
          border-radius: 0.02rem 0.02rem 0.02rem 0.02rem;
          text-align: center;
          cursor: pointer;
          color: #fcfcfc;
          line-height: 0.26rem;
          font-size: 0.08rem;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .left {
      padding-top: 0.3rem;
      .time {
        display: flex;
        align-items: flex-start;
        margin-right: 0.17rem;
        span {
          line-height: 0.16rem;
          color: #666666;
          font-size: 0.08rem;
          padding: 0.02rem 0.24rem;

          background: #f5f5f5;
          border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
          margin-right: 0.17rem;
        }
        .dot {
          width: 0.13rem;
          height: 0.13rem;
          border: 0.02rem solid #02aeec;
          border-radius: 50%;
          margin: 0.02rem 0 0.06rem;
        }
        .line {
          width: 0.24rem;
          height: 0.04rem;
          margin: 0.06rem 0;
          background: #f5f5f5;
          margin-left: 0.07rem;
        }
        .time_c {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .h_line {
          width: 0.04rem;
          height: 2.3rem;
          background: #f5f5f5;
          margin-bottom: 0.04rem;
        }
        .a_line {
          width: 0.04rem;
          height: 0.15rem;
          background: #f5f5f5;
          position: absolute;
          top: -0.2rem;
        }
        &:last-child {
          .h_line {
            height: 2.13rem;
          }
        }
      }
    }
  }

  .page_wrap {
    background: #fcfcfc;
    display: flex;
    justify-content: center;
    padding-bottom: .4rem;
    img {
      width: 0.24rem;
      cursor: pointer;
      margin: 0 0.03rem;
    }
    .item {
      cursor: pointer;
      width: 0.24rem;
      height: 0.24rem;
      background: #ffffff;
      border-radius: 0.02rem 0.02rem 0.02rem 0.02rem;
      opacity: 1;
      border: 0.01rem solid #cccccc;
      line-height: 0.24rem;
      margin: 0 0.03rem;

      font-size: 0.08rem;
      &.active,
      &:hover {
        background: #02aeec;
        border-radius: 0.02rem 0.02rem 0.02rem 0.02rem;
        border: none;
        color: #fff;
      }
    }
  }
}
</style>
